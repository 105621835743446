import searchState from './use-global-state'
import useInitialStateFromAsyncStorage from './use-initial-state-from-async-storage'

export default function useGlobalArtistSearchQueryState() {
  // return searchState.useGlobalState('query')
  const [state, setState] = searchState.useGlobalState('query')
  // return useInitialStateFromAsyncStorage([state, setState], {
  //   asyncStorageKey: 'query',
  //   debounce: 1000,
  // })
  return [state, setState] as const
}

// hi
