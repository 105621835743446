import React from 'react'
import { NextSeo } from 'next-seo'

type Props = React.ComponentProps<typeof NextSeo>

export default function SEO(p: Props) {
  const {
    title,
    description = 'Book an artist from your pocket.',
    twitter = {},
    openGraph = {},
    ...props
  } = p
  return (
    <NextSeo
      {...props}
      title={title}
      description={description}
      twitter={{
        cardType: 'summary_large_image',
        handle: '@beatgig',
        site: '@beatgig',
        ...twitter,
      }}
      openGraph={{
        title,
        description,
        ...openGraph,
      }}
    />
  )
}
