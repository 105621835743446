import { useEffect, useRef } from 'react'

export default function useConsoleLog(...args: unknown[]) {
  // track when args changed based on their stringified version
  const stringifiedArgs = JSON.stringify(args ?? [])

  const latestArgs = useRef(args ?? [])

  useEffect(
    function updateArgs() {
      latestArgs.current = args ?? []
    },
    [args]
  )

  useEffect(
    function logArgsWhenTheyChange() {
      console.log(...latestArgs.current)
    },
    [stringifiedArgs]
  )
}
