import React from 'react'
import { Link } from 'expo-next-react-navigation'
import { AuthedRoutes } from '../routes/authed-routes'
import { styled } from 'dripsy'

type Props = {
  children: React.ReactNode
} & Omit<React.ComponentProps<typeof StyledLink>, 'routeName'>

const StyledLink = styled(Link)({})

export default function LinkToDashboard({ isText = false, ...props }: Props) {
  return <StyledLink isText={isText} {...props} {...AuthedRoutes.dashboard()} />
}
